@charset "UTF-8";
/**
Theme Name: Bare Bones
Theme URI: http://github.com/benchmarkstudios/barebones
Author: Benchmark
*/
/**
 * Default variables and helper mixins
 */
/**
 * Space between columns
 */
/**
 * Max width of container
 */
/**
 * Number of total columns
 */
/**
 * Breakpoints
 */
/**
 * Grid loop mixin
 */
/**
 * Clearfix
 */
/**
 * Grid scaffolding
 */
.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

.row {
  list-style: none;
  margin-left: -20px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.row--gutterless {
  margin-left: 0;
}

.col {
  float: left;
  padding-left: 20px;
  box-sizing: border-box;
}

.row--gutterless .col {
  padding-left: 0;
}

/**
 * Columns generator
 */
@media only screen and (min-width: 1200px) {
  .col--lg-offset-0 {
    margin-left: 0%;
  }
  .col--lg-1 {
    width: 8.3333333333%;
  }
  .col--lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col--lg-2 {
    width: 16.6666666667%;
  }
  .col--lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col--lg-3 {
    width: 25%;
  }
  .col--lg-offset-3 {
    margin-left: 25%;
  }
  .col--lg-4 {
    width: 33.3333333333%;
  }
  .col--lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col--lg-5 {
    width: 41.6666666667%;
  }
  .col--lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col--lg-6 {
    width: 50%;
  }
  .col--lg-offset-6 {
    margin-left: 50%;
  }
  .col--lg-7 {
    width: 58.3333333333%;
  }
  .col--lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col--lg-8 {
    width: 66.6666666667%;
  }
  .col--lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col--lg-9 {
    width: 75%;
  }
  .col--lg-offset-9 {
    margin-left: 75%;
  }
  .col--lg-10 {
    width: 83.3333333333%;
  }
  .col--lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col--lg-11 {
    width: 91.6666666667%;
  }
  .col--lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col--lg-12 {
    width: 100%;
  }
  .col--lg-offset-12 {
    margin-left: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col--md-offset-0 {
    margin-left: 0%;
  }
  .col--md-1 {
    width: 8.3333333333%;
  }
  .col--md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col--md-2 {
    width: 16.6666666667%;
  }
  .col--md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col--md-3 {
    width: 25%;
  }
  .col--md-offset-3 {
    margin-left: 25%;
  }
  .col--md-4 {
    width: 33.3333333333%;
  }
  .col--md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col--md-5 {
    width: 41.6666666667%;
  }
  .col--md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col--md-6 {
    width: 50%;
  }
  .col--md-offset-6 {
    margin-left: 50%;
  }
  .col--md-7 {
    width: 58.3333333333%;
  }
  .col--md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col--md-8 {
    width: 66.6666666667%;
  }
  .col--md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col--md-9 {
    width: 75%;
  }
  .col--md-offset-9 {
    margin-left: 75%;
  }
  .col--md-10 {
    width: 83.3333333333%;
  }
  .col--md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col--md-11 {
    width: 91.6666666667%;
  }
  .col--md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col--md-12 {
    width: 100%;
  }
  .col--md-offset-12 {
    margin-left: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col--sm-offset-0 {
    margin-left: 0%;
  }
  .col--sm-1 {
    width: 8.3333333333%;
  }
  .col--sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col--sm-2 {
    width: 16.6666666667%;
  }
  .col--sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col--sm-3 {
    width: 25%;
  }
  .col--sm-offset-3 {
    margin-left: 25%;
  }
  .col--sm-4 {
    width: 33.3333333333%;
  }
  .col--sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col--sm-5 {
    width: 41.6666666667%;
  }
  .col--sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col--sm-6 {
    width: 50%;
  }
  .col--sm-offset-6 {
    margin-left: 50%;
  }
  .col--sm-7 {
    width: 58.3333333333%;
  }
  .col--sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col--sm-8 {
    width: 66.6666666667%;
  }
  .col--sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col--sm-9 {
    width: 75%;
  }
  .col--sm-offset-9 {
    margin-left: 75%;
  }
  .col--sm-10 {
    width: 83.3333333333%;
  }
  .col--sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col--sm-11 {
    width: 91.6666666667%;
  }
  .col--sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col--sm-12 {
    width: 100%;
  }
  .col--sm-offset-12 {
    margin-left: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col--xs-offset-0 {
    margin-left: 0%;
  }
  .col--xs-1 {
    width: 8.3333333333%;
  }
  .col--xs-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col--xs-2 {
    width: 16.6666666667%;
  }
  .col--xs-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col--xs-3 {
    width: 25%;
  }
  .col--xs-offset-3 {
    margin-left: 25%;
  }
  .col--xs-4 {
    width: 33.3333333333%;
  }
  .col--xs-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col--xs-5 {
    width: 41.6666666667%;
  }
  .col--xs-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col--xs-6 {
    width: 50%;
  }
  .col--xs-offset-6 {
    margin-left: 50%;
  }
  .col--xs-7 {
    width: 58.3333333333%;
  }
  .col--xs-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col--xs-8 {
    width: 66.6666666667%;
  }
  .col--xs-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col--xs-9 {
    width: 75%;
  }
  .col--xs-offset-9 {
    margin-left: 75%;
  }
  .col--xs-10 {
    width: 83.3333333333%;
  }
  .col--xs-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col--xs-11 {
    width: 91.6666666667%;
  }
  .col--xs-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col--xs-12 {
    width: 100%;
  }
  .col--xs-offset-12 {
    margin-left: 100%;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * Custom variables
 */
/**
 * Base values
 */
/**
 * Breakpoints
 */
/**
 * Grid
 */
/**
 * Category Colours
 */
/*
 * Media query to respond to a minimum size (mobile first)
 */
/*
 * Media query to respond to a maximum size
 */
/*
 * Output font size in px/rem
 */
/*
 * Placeholder mixin for <input>
 */
/**
 * Clearfix
 */
* {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  background-color: white;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

html, body {
  min-height: 100%;
  position: relative;
}

input,
select,
textarea,
button {
  color: inherit;
  display: inline-block;
}

input[disabled],
select[disabled],
textarea[disabled],
button[disabled] {
  cursor: not-allowed;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table th,
table td {
  text-align: left;
}

.sr-only {
  font-size: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.visible-lg {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .visible-lg {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-md {
  display: none;
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .visible-md {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

.visible-sm {
  display: none;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .visible-sm {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .hidden-sm {
    display: none !important;
  }
}

.visible-xs {
  display: none;
}

@media only screen and (max-width: 768px) {
  .visible-xs {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }
}

.alignleft {
  float: left;
}

img.alignleft {
  margin-right: 18.2px;
}

.alignright {
  float: right;
}

img.alignright {
  margin-left: 18.2px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.aligncenter {
  margin-bottom: 18.2px;
}

.textcenter {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.alignbottom {
  position: absolute;
  bottom: 9.1px;
}

.alignbottom p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .alignbottom {
    position: static;
  }
}

@media only screen and (max-height: 700px) {
  .alignbottom {
    position: static;
  }
}

body {
  color: #000;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.5px;
}

@font-face {
  font-family: 'fontello';
  src: url("fonts/fontello.eot?40052688");
  src: url("fonts/fontello.eot?40052688#iefix") format("embedded-opentype"), url("fonts/fontello.woff?40052688") format("woff"), url("fonts/fontello.ttf?40052688") format("truetype"), url("fonts/fontello.svg?40052688#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?40052688#fontello') format('svg');
  }
}
*/
[class^="icon--"]:before, [class*=" icon--"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon--right-dir:before {
  content: '\e800';
}

/* '' */
.icon--left-dir:before {
  content: '\e801';
}

/* '' */
.icon--instagram:before {
  content: '\e802';
}

/* '' */
.icon--pinterest:before {
  content: '\e803';
}

/* '' */
.icon--menu:before {
  content: '\e804';
}

/* '' */
.icon--gplus:before {
  content: '\e805';
}

/* '' */
.icon--facebook:before {
  content: '\e806';
}

/* '' */
.icon--twitter:before {
  content: '\e807';
}

/* '' */
.icon--youtube:before {
  content: '\e808';
}

/* '' */
.icon--arrow-right:before {
  content: '\e809';
}

/* '' */
.icon--icon:before {
  content: '\e80a';
}

/* '' */
.icon--zoom-in:before {
  content: '\e80b';
}

/* '' */
.icon--paper-plane-empty:before {
  content: '\e80c';
}

/* '' */
.icon--envelope:before {
  content: '\e80d';
}

/* '' */
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr,
ul,
ol,
dl,
address {
  margin-bottom: 18.2px;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  vertical-align: top;
}

img[width], img[height] {
  max-width: none;
}

/**
 * Blanket input styles for normalisation
 */
input,
select,
textarea {
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 0;
  color: #000;
  line-height: 27.3px;
  height: 27.3px;
  padding: 0 9.1px;
  width: 100%;
}

input[type="submit"] {
  -webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto;
}

input[type="file"] {
  line-height: normal;
  height: auto;
  padding: 4.55px 9.1px;
}

input[disabled] {
  background-color: whitesmoke;
  cursor: not-allowed;
}

select {
  line-height: normal;
  padding: 0;
  padding-left: 9.1px;
}

textarea {
  min-height: 72.8px;
  overflow: auto;
  vertical-align: top;
}

/**
 * Form utility classes
 */
.form--inline .form__group {
  display: inline-block;
}

.checkbox,
.radio {
  display: block;
  padding-left: 18.2px;
}

.checkbox.inline,
.radio.inline {
  display: inline-block;
}

.checkbox > input,
.radio > input {
  float: left;
  margin-left: -18.2px;
  margin-top: 4px;
}

.form__group {
  display: block;
  margin-bottom: 18.2px;
}

.form__label {
  display: block;
  margin-bottom: 9.1px;
}

/**
 * Input Group
 *
 * Allows for prepend/append of elements 
 * (such as icons) on an input.
 */
.input-group {
  position: relative;
}

.input-group > input {
  padding: 0 36.4px;
}

.input-group .input-group__addon {
  line-height: 27.3px;
  height: 27.3px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 27.3px;
}

.input-group .input-group__addon:first-child {
  left: 0;
}

.input-group .input-group__addon:last-child {
  right: 0;
}

.form--enquiry .form__group {
  margin-bottom: 18.2px;
  text-align: left;
}

.form--enquiry .form__group label {
  font-weight: 600;
}

.form--enquiry .form__group input,
.form--enquiry .form__group select,
.form--enquiry .form__group textarea {
  border-color: #CCC;
}

.form--enquiry button {
  float: right;
}

/**
 * Form Builder
 */
.formBuilderForm {
  padding: 0 !important;
}

.formBuilderForm .control-group {
  overflow: hidden;
  margin-bottom: 9.1px;
}

.formBuilderForm .formBuilderLabel, .formBuilderForm .formBuilderLabelRequired {
  width: 50% !important;
  float: left;
  font-weight: 700;
  margin: 0 !important;
}

@media screen and (max-width: 768px) {
  .formBuilderForm .formBuilderLabel, .formBuilderForm .formBuilderLabelRequired {
    width: 100% !important;
    float: none;
    margin-bottom: 9.1px !important;
  }
}

.formBuilderForm .formBuilderInput, .formBuilderForm .formBuilderSelect, .formBuilderForm .formBuilderLargeTextarea {
  width: 50%;
  float: right;
  vertical-align: top;
  margin: 0 !important;
}

@media screen and (max-width: 768px) {
  .formBuilderForm .formBuilderInput, .formBuilderForm .formBuilderSelect, .formBuilderForm .formBuilderLargeTextarea {
    width: 100% !important;
    float: none;
  }
}

.formBuilderForm input, .formBuilderForm select, .formBuilderForm textarea {
  border: 1px #ccc solid;
}

@media screen and (max-width: 768px) {
  .formBuilderForm input[type="submit"] {
    width: 100% !important;
  }
}

.formBuilderCheckboxDescription {
  display: none;
}

ul,
ol {
  margin-left: 18.2px;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1, .h1 {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 9.1px;
  line-height: 1em;
}

@media screen and (max-width: 768px) {
  h1, .h1 {
    margin: 0;
  }
}

h2 {
  font-weight: 300;
}

h3 {
  font-family: "PT Serif", serif;
  font-style: italic;
  font-size: 2rem;
  line-height: 1.2em;
}

.page-title {
  font-family: "PT Serif", serif;
  font-style: italic;
  font-size: 1.75rem;
  line-height: 1.2em;
  text-transform: capitalize;
  margin-bottom: 9.1px;
}

@media screen and (max-width: 768px) {
  .page-title {
    font-size: 1.5em;
  }
}

.itinerary-title {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  padding-left: 225px;
}

main.page {
  padding-top: 54.6px;
}

@media screen and (max-width: 768px) {
  main {
    padding-left: 0;
  }
  main.page {
    padding-top: 109.2px;
  }
}

.grecaptcha-badge {
  bottom: 35px !important;
}

header.header {
  background-color: #fff;
  width: 225px;
  position: fixed;
  padding: 18.2px;
  height: 100%;
  z-index: 1002;
  padding-top: 9.1px;
}

@media screen and (max-width: 768px) {
  header.header {
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  header.header {
    height: 100vh;
  }
}

header.header p, header.header .logo {
  margin-bottom: 9.1px;
}

header.header h1, header.header .h1 {
  font-weight: 700;
  margin-bottom: 12.74px;
  letter-spacing: 0;
}

header.header .btn--enquire {
  display: block;
}

header.header .logo {
  position: relative;
  z-index: 1002;
  margin-bottom: 18.2px;
}

@media screen and (max-width: 768px) {
  header.header .logo {
    display: block;
    margin: 0 auto 9.1px auto;
  }
}

header.header .btn--navigation, header.header .btn--mobileenquire {
  line-height: 54px;
  padding-top: 8px;
  font-size: 1.5rem;
  vertical-align: middle;
  position: absolute;
  z-index: 1002;
  top: 9.1px;
}

header.header .btn--navigation {
  margin-top: 1px;
  display: none;
}

@media screen and (max-width: 768px) {
  header.header .btn--navigation {
    display: inline-block;
  }
}

header.header .btn--mobileenquire {
  right: 18.2px;
  font-size: 1.2em;
}

header.header .telephone {
  margin-bottom: 9.1px;
  line-height: 2.5em;
}

@media screen and (max-width: 768px) {
  header.header .telephone {
    letter-spacing: 0;
    clear: both;
    line-height: 2.5em;
    position: relative;
    z-index: 1002;
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 0;
  }
}

header.header .enquire {
  background-color: #000;
  color: #fff;
  float: right;
  padding: 0 1em;
  margin-right: -20.2px;
}

@media screen and (max-width: 768px) {
  header.header .enquire {
    float: none;
  }
}

header.header .logo {
  width: 100%;
}

@media screen and (max-width: 768px) {
  header.header .logo {
    max-width: 130px;
  }
}

header.header .group {
  font-style: italic;
  letter-spacing: 0;
}

header.header .group a {
  border-bottom: 1px #000 dashed;
}

header.header .group a:hover {
  border: 0;
}

.mobile--enquire {
  margin-bottom: 36.4px !important;
}

footer {
  position: absolute;
  bottom: 9.1px;
  z-index: 2;
  color: #fff;
  right: 18.2px;
}

@media screen and (max-width: 768px) {
  footer {
    text-align: center;
    width: 100%;
    right: 0;
  }
  footer li {
    text-align: center;
  }
}

body.home footer {
  position: absolute;
  text-shadow: 1px 1px #000;
}

.btn, .formBuilderForm input[type=submit] {
  height: auto !important;
  background-color: #000;
  color: #fff;
  display: inline-block;
  line-height: 29.12px;
  padding: 0 27.3px;
  text-align: center;
  width: auto;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s;
}

.btn::-moz-focus-inner, .formBuilderForm input[type=submit]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--link {
  background-color: transparent;
  color: #000;
}

.btn--primary, .formBuilderForm input[type=submit] {
  border: 2px #fff solid;
  cursor: pointer;
}

.btn--primary:hover, .formBuilderForm input[type=submit]:hover {
  background-color: #fff;
  border: 2px #000 solid;
  color: #000;
}

.nav {
  list-style: none;
  margin: 0;
}

.nav > li {
  display: inline-block;
  margin-right: 18.2px;
}

.nav > li:last-child {
  margin-right: 0;
}

.nav > li > a {
  display: block;
}

.nav.nav-primary {
  text-transform: uppercase;
  margin: 18.2px 0;
}

@media screen and (max-width: 768px) {
  .nav.nav-primary {
    margin: 0 0 36.4px 0;
  }
}

.nav.nav-primary li {
  display: block;
  background-position: 0 center;
}

.nav.nav-primary li:before {
  font-family: 'fontello';
  content: '\e80a';
  display: inline-block;
  float: left;
  margin-right: 9.1px;
  font-size: 1rem;
  padding-left: 5px;
}

.nav.nav-primary li a {
  line-height: 2em;
}

.nav.nav-social {
  margin-bottom: 18.2px;
  overflow: hidden;
}

.nav.nav-social li {
  font-size: 1.2rem;
  margin-right: 0.62em;
}

.nav.nav-secondary {
  margin-bottom: 18.2px;
  overflow: hidden;
}

.nav.nav-secondary li {
  display: block;
}

.nav.nav-brand li {
  width: 33%;
  display: inline-block;
  margin: 0;
  float: left;
}

@media screen and (max-width: 768px) {
  .nav.nav-footer {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .nav-collapse {
    display: none;
    background: #fff;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 109.2px 18.2px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.slider {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .slider {
    overflow: visible;
  }
}

.home .slider {
  height: 100%;
}

.home .slide {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: table !important;
}

.home .slide .slide__content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  text-shadow: 2px 2px #000;
}

.home .slide .slide__content a, .home .slide .slide__content i {
  display: block;
}

.home .slide .slide__content i {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .home .slide .slide__content {
    padding-top: 27.3px;
  }
}

.slick-dots {
  position: absolute;
  bottom: 54.6px;
  color: #fff;
  margin: 0;
}

.home .slick-dots {
  bottom: 30%;
}

.slick-dots li {
  text-indent: -1000px;
  box-shadow: 1px 1px #000;
  border-radius: 100%;
  border: 2px #fff solid;
  list-style: none;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 18.2px;
  cursor: pointer;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots li.slick-active {
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    display: none !important;
  }
}

.slide__caption {
  text-align: center;
  color: #666;
  margin-top: 18.2px;
}

.slick-arrow {
  width: 30px;
  height: 30px;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 40%;
  z-index: 900;
  font-size: 1rem;
}

.slick-arrow.slick-prev {
  left: 109.2px;
}

.slick-arrow.slick-next {
  right: 109.2px;
}

@media screen and (max-width: 768px) {
  .slick-arrow {
    top: 35%;
  }
  .slick-arrow.slick-prev {
    left: -15px;
  }
  .slick-arrow.slick-next {
    right: -15px;
  }
}

body.home {
  overflow: hidden;
}

.itinerary {
  background-color: #fff;
  padding: 18.2px;
  margin-bottom: 18.2px;
  position: relative;
  min-height: 560px;
}

.itinerary img {
  max-width: 100%;
  width: 100%;
}

.itinerary strong {
  font-family: "PT Serif", serif;
  font-weight: 300;
  font-style: italic;
  display: block;
  clear: both;
  margin-bottom: 1em;
}

.itinerary .itinerary__image {
  margin-bottom: 1em;
}

.more-details {
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  padding: 0.25em 1em;
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: bold;
}

.category-heading {
  color: #fff;
  text-shadow: 1px 1px #000;
  margin-bottom: 18.2px;
}

.category-heading h1 {
  text-transform: uppercase;
  font-family: "PT Sans", sans-serif;
}

header.heading .icon {
  font-size: 2.5rem;
}

.archive.category header.heading {
  padding: 18.2px;
}

main .polar, .polar.current-menu-item a, .polar.menu-item a:hover, .polar.current-post-ancestor, .polar.menu-item:hover:before, .polar.current-menu-item:before, .polar.current-post-ancestor:before {
  color: #4AA7D0;
}

main .desert, .desert.current-menu-item a, .desert.menu-item a:hover, .desert.current-post-ancestor, .desert.menu-item:hover:before, .desert.current-menu-item:before, .desert.current-post-ancestor:before {
  color: #EA9A3E;
}

main .jungle, .jungle.current-menu-item a, .jungle.menu-item a:hover, .jungle.current-post-ancestor, .jungle.menu-item:hover:before, .jungle.current-menu-item:before, .jungle.current-post-ancestor:before {
  color: #1E5731;
}

main .mountain, .mountain.current-menu-item a, .mountain.menu-item a:hover, .mountain.current-post-ancestor, .mountain.menu-item:hover:before, .mountain.current-menu-item:before, .mountain.current-post-ancestor:before {
  color: #925E32;
}

main .river, .river.current-menu-item a, .river.menu-item a:hover, .river.current-post-ancestor, .river.menu-item:hover:before, .river.current-menu-item:before, .river.current-post-ancestor:before {
  color: #243357;
}

main .epic-weekends, .epic-weekends.current-menu-item a, .epic-weekends.menu-item a:hover, .epic-weekends.current-post-ancestor, .epic-weekends.menu-item:hover:before, .epic-weekends.current-menu-item:before, .epic-weekends.current-post-ancestor:before {
  color: #4f0e3c;
}

main .world-firsts, .world-firsts.current-menu-item a, .world-firsts.menu-item a:hover, .world-firsts.current-post-ancestor, .world-firsts.menu-item:hover:before, .world-firsts.current-menu-item:before, .world-firsts.current-post-ancestor:before {
  color: #aa2d2d;
}

main .a-trip-with-no-name, .a-trip-with-no-name.current-menu-item a, .a-trip-with-no-name.menu-item a:hover, .a-trip-with-no-name.current-post-ancestor, .a-trip-with-no-name.menu-item:hover:before, .a-trip-with-no-name.current-menu-item:before, .a-trip-with-no-name.current-post-ancestor:before {
  color: #47475E;
}

.post, article.page {
  background-color: #fff;
  padding: 18.2px;
  margin-bottom: 36.4px;
  position: relative;
}

.post img, article.page img {
  max-width: 100%;
}

.post .slider, article.page .slider {
  opacity: 0;
  transition: 0.5s;
  margin-bottom: 36.4px;
}

.post .slider.slick-initialized, article.page .slider.slick-initialized {
  opacity: 1;
}

.post .slider img, article.page .slider img {
  width: 100%;
  opacity: 0.25;
  transition: 0.5s;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .post .slider img, article.page .slider img {
    opacity: 1;
  }
}

.post .slider .slick-current img, article.page .slider .slick-current img {
  opacity: 1;
}

.acf-map {
  width: 100%;
  margin: 20px 0;
}

.post-content ul li {
  text-indent: -5px;
  list-style: none;
}

.post-content ul li:before {
  content: "-";
  position: relative;
  left: -5px;
  float: left;
  margin-right: 5px;
}
