/**
 * Blanket input styles for normalisation
 */

input, 
select, 
textarea {
  background-color: $base-background-colour;
  border: solid 1px $base-colour;
  border-radius: 0;
  color: $base-colour;
  line-height: ($base-spacing-unit * 1.5);
  height: ($base-spacing-unit * 1.5);
  padding: 0 ($base-spacing-unit / 2);
  width: 100%;
}

input[type="submit"] {
    -webkit-appearance: none;
}

input[type="checkbox"], 
input[type="radio"] {
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto;
}

input[type="file"] {
  line-height: normal;
  height: auto;
  padding: ($base-spacing-unit / 4) ($base-spacing-unit / 2);
}

input[disabled] {
  background-color: whitesmoke;
  cursor: not-allowed;
}

select {
  line-height: normal;
  padding: 0;
  padding-left: ($base-spacing-unit / 2);
}

textarea {
  min-height: ($base-spacing-unit * 4);
  overflow: auto;
  vertical-align: top;
}


/**
 * Form utility classes
 */

.form--inline {
  .form__group {
    display: inline-block;
  }
}

.checkbox,
.radio {
  display: block;
  padding-left: $base-spacing-unit;
  &.inline {
    display: inline-block;
  }
  > input {
    float: left;
    margin-left: -($base-spacing-unit);
    margin-top: 4px;
  }
}

.form__group {
  display: block;
  margin-bottom: $base-spacing-unit;
}

.form__label {
  display: block;
  margin-bottom: ($base-spacing-unit / 2);
}


/**
 * Input Group
 *
 * Allows for prepend/append of elements 
 * (such as icons) on an input.
 */

.input-group {
  position: relative;
  > input {
    padding: 0 ($base-spacing-unit * 2);
  }
  .input-group__addon {
    line-height: ($base-spacing-unit * 1.5);
    height: ($base-spacing-unit * 1.5);
    position: absolute;
    text-align: center;
    top: 0;
    width: ($base-spacing-unit * 1.5);
    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
  }
}


.form--enquiry {
  .form__group {
    margin-bottom: $base-spacing-unit;
    text-align: left;
    label {
      font-weight: 600;
    }
    input,
    select,
    textarea {
      border-color: #CCC;
    }
  }
  button {
    float: right; 
  }
}


/**
 * Form Builder
 */

.formBuilderForm {
  padding: 0 !important;
  .control-group {
    overflow: hidden;
    margin-bottom: $base-spacing-unit / 2;
  }
  .formBuilderLabel, .formBuilderLabelRequired {
    width: 50% !important;
    float: left;
    font-weight: 700;
    margin: 0 !important;
    @include resp-max($breakpoint-sm) {
      width: 100% !important;
      float: none;
      margin-bottom: $base-spacing-unit / 2 !important;
    }
  }
  .formBuilderInput, .formBuilderSelect, .formBuilderLargeTextarea {
    width: 50%;
    float: right;
    vertical-align: top;
    margin: 0 !important;
    @include resp-max($breakpoint-sm) {
      width: 100% !important;
      float: none;
    }
  }
  input, select, textarea {
    border: 1px #ccc solid;
  }
  input[type="submit"] {
    @include resp-max($breakpoint-sm) {
      width: 100% !important;
    }
  }
}

.formBuilderCheckboxDescription {
  display: none;
}