main {
	min-height: 100vh;
	background-attachment: fixed;
	background-size: cover;
	padding-left: $header-width;
	&.page {
		padding-top: $base-spacing-unit * 3;
	}
	@include resp-max($breakpoint-sm) {
			padding-left: 0;
		&.page {
			padding-top: $base-spacing-unit * 6;
		}
	}
}

.grecaptcha-badge {
	bottom: 35px !important;
}