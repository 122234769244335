.post, article.page {
	background-color: #fff;
	padding: $base-spacing-unit;
	margin-bottom: $base-spacing-unit * 2;
	position: relative;
	img {
		max-width: 100%;
	}
	.slider {
		opacity: 0;
		transition: 0.5s;
		margin-bottom: $base-spacing-unit * 2;
		&.slick-initialized {
			opacity: 1;
		}
		img {
			width: 100%;
			opacity: 0.25;
			transition: 0.5s;
			cursor: pointer;
			@include resp-max($breakpoint-sm) {
				opacity: 1;
			}
		}
		.slick-current img {
			opacity: 1;
		}		
	}
}

.acf-map {
	width: 100%;
	margin: 20px 0;
}

.post-content {
	ul {
		li {
			text-indent:-5px;
			list-style: none;
			&:before{ 
				content:"-";
				position:relative;
				left:-5px;
				float: left;
				margin-right: 5px;
			}
		}
	}
}