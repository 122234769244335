.slider {
  overflow: hidden;
  @include resp-max($breakpoint-sm) {
    overflow: visible;
  }
}

.home .slider {
  height: 100%;
}

.home .slide {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: table !important;
  .slide__content {
    display: table-cell;
    text-align: center;
    vertical-align: middle; 
    color: #fff; 
    text-shadow: 2px 2px $base-colour;
    a, i {
      display: block;
    }
    i {
      font-size: 2rem;
    }
    @include resp-max($breakpoint-sm) {
      padding-top: $base-spacing-unit * 1.5;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: $base-spacing-unit * 3;
  color: #fff;
  margin: 0;
  .home & {
    bottom: 30%;
  }
  li {
    text-indent: -1000px;
    box-shadow: 1px 1px #000;
    border-radius: 100%;
    border: 2px #fff solid;
    list-style: none;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: $base-spacing-unit;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.slick-active {
      background-color: #fff;
    }
  }
  @include resp-max($breakpoint-sm) {
    display: none !important;
  }
}

.slide__caption {
  text-align: center;
  color: #666;
  margin-top: $base-spacing-unit;
}

.slick-arrow {
  width: 30px;
  height: 30px;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 40%;
  z-index: 900;
  font-size: 1rem;
  &.slick-prev {
    left: $base-spacing-unit * 6;
  }
  &.slick-next {
    right: $base-spacing-unit * 6;
  }
  @include resp-max($breakpoint-sm) {
    top: 35%;
    &.slick-prev {
      left: -15px;
    }
    &.slick-next {
      right: -15px;
    }
  }
}