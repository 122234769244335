.alignleft {
  float: left;
}

img.alignleft {
  margin-right: $base-spacing-unit;
}

.alignright {
  float: right;
}

img.alignright {
  margin-left : $base-spacing-unit;
}

.aligncenter {
  display: block;
  margin: {
    left: auto;
    right: auto;
  }
}

img.aligncenter {
  margin-bottom: $base-spacing-unit;
}

.textcenter {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.alignbottom {
  position: absolute;
  bottom: $base-spacing-unit / 2;
  p:last-child {
    margin-bottom: 0;
  }
  @include resp-max($breakpoint-sm) {
    position: static;
  }
  @media only screen and (max-height: 700px) {
    position: static;
  }
}