h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
  font-weight: bold;
}

h1, .h1 {
	font-size: 0.8rem;
	text-transform: uppercase;
	font-weight: 300;
	margin-bottom: $base-spacing-unit / 2;
	line-height: 1em;
	@include resp-max($breakpoint-sm) {
		margin: 0;
	}
}

h2 {
	font-weight: 300;
}

h3 {
	font-family: $alt-font-stack;
	font-style: italic;
	font-size: 2rem;
	line-height: 1.2em;
}

.page-title {
	font-family: $alt-font-stack;
	font-style: italic;
	font-size: 1.75rem;
	line-height: 1.2em;
	text-transform: capitalize;
	margin-bottom: $base-spacing-unit / 2;
	@include resp-max($breakpoint-sm) {
		font-size: 1.5em;
	}
}

.itinerary-title {
	font-family: $base-font-stack;
	font-size: 1rem;
	font-style: normal;
	font-weight: 300;
}