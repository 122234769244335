ul, 
ol {
  margin-left: $base-spacing-unit;
}

li {
  > ul, 
  > ol {
    margin-bottom: 0;
  }
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
}