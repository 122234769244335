/**
 * Default variables and helper mixins
 */

@import 'defaults';
@import 'mixins';


/**
 * Grid scaffolding
 */

.container {
  @include clearfix;
  max-width: $grid-max-width;
  margin: 0 auto;
  padding: 0 $grid-gutter;
}

.row {
  @include clearfix;
  list-style: none;
  margin-left: -$grid-gutter;
  &.row--gutterless {
    margin-left: 0;
  }
}

.col {
  float: left;
  padding-left: $grid-gutter;
  box-sizing: border-box;
  .row--gutterless & {
    padding-left: 0;
  }
}



/**
 * Columns generator
 */

@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $declaration: nth($breakpoint, 2);

  @media only screen and #{$declaration} {
    @include grid-loop($name);
  }
}