header.header {
	background-color: #fff;
	width: $header-width;
	position: fixed;
	padding: $base-spacing-unit;
	height: 100%;
	z-index: 1002;
	padding-top: $base-spacing-unit / 2;
	@include resp-max($breakpoint-sm) {
		width: 100%;
		height: auto;
		padding-bottom: 0;
	}
	@include resp-min($breakpoint-lg) {
		height: 100vh;
	}	
	p, .logo {
		margin-bottom: $base-spacing-unit / 2;
	}
	h1, .h1 {
		font-weight: 700;
		margin-bottom: $base-spacing-unit * 0.7;
		letter-spacing: 0;
	}
	.btn--enquire {
		display: block;
	}
	.logo {
		position: relative;
		z-index: 1002;
		margin-bottom: $base-spacing-unit;
		@include resp-max($breakpoint-sm) {
			display: block;
			margin: 0 auto ($base-spacing-unit / 2) auto;
		}
	}
	.btn--navigation, .btn--mobileenquire {
		line-height: 54px;
		padding-top: 8px;
		font-size: 1.5rem;
		vertical-align: middle;
		position: absolute;
		z-index: 1002;
		top: $base-spacing-unit / 2;
	}
	.btn--navigation {
		margin-top: 1px;
		display: none;
		@include resp-max($breakpoint-sm) {
			display: inline-block;
		}
	}	
	.btn--mobileenquire {
		right: $base-spacing-unit;
		font-size: 1.2em;
	}
	.telephone {
		margin-bottom: $base-spacing-unit / 2;
		line-height: 2.5em;
		@include resp-max($breakpoint-sm) {
			letter-spacing: 0;
			clear: both;
			line-height: 2.5em;
			position: relative;
			z-index: 1002;
			font-size: 0.8rem;		
			text-align: center;	
			margin-bottom: 0;
		}
	}
	.enquire {
		background-color: #000;
		color: #fff;
		float: right;
		padding: 0 1em;
		margin-right: -$base-spacing-unit - 2;
		@include resp-max($breakpoint-sm) {
			float: none;
		}
	}
	.logo {
		width: 100%;
		@include resp-max($breakpoint-sm) {
			max-width: 130px;
		}
	}
	.group {
		font-style: italic;
		letter-spacing: 0;
		a {
			border-bottom: 1px $base-colour dashed;
			&:hover {
				border: 0;
			}
		}
	}
}

.mobile--enquire {
	margin-bottom: $base-spacing-unit * 2 !important;
}