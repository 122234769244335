.nav {
  list-style: none;
  margin: 0;
  > li {
      display: inline-block;
      margin-right: $base-spacing-unit;
      &:last-child {
        margin-right: 0;
      }
    > a {
      display: block;
    }
  }
  &.nav-primary {
    text-transform: uppercase;
    margin: $base-spacing-unit 0;
    @include resp-max($breakpoint-sm) {
      margin: 0 0 ($base-spacing-unit * 2) 0;
    } 
    li {
      display: block;
      background-position: 0 center;
      &:before {
        font-family: 'fontello';
        content: '\e80a';
        display: inline-block;
        float: left;
        margin-right: $base-spacing-unit / 2;
        font-size: 1rem;
        padding-left: 5px;
      }
      a {
        line-height: 2em;
      }
    }
  }
  &.nav-social {
    margin-bottom: $base-spacing-unit;
    overflow: hidden;
    li {
      font-size: 1.2rem;
      margin-right: 0.62em;
    }
  }
  &.nav-secondary {
    margin-bottom: $base-spacing-unit;
    overflow: hidden;
    li {
      display: block;
    }
  }
  &.nav-brand {
    li {
      width: 33%;
      display: inline-block;
      margin: 0;
      float: left;
    }
  }
  &.nav-footer {
    @include resp-max($breakpoint-sm) {
      text-align: center;
    }
  }
}

.nav-collapse {
  @include resp-max($breakpoint-sm) {
    display: none;
    background: #fff;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ($base-spacing-unit * 6) $base-spacing-unit;
  }
}

* {
  margin: 0;
  padding: 0; 
}