.btn, .formBuilderForm input[type=submit] {
  height: auto !important;
  background-color: $base-colour;
  color: $base-background-colour;
  display: inline-block;
  line-height: ($base-spacing-unit * 1.6);
  padding: 0 ($base-spacing-unit * 1.5);
  text-align: center;
  width: auto;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--link {
  background-color: transparent;
  color: $base-colour;
}

.btn--primary, .formBuilderForm input[type=submit] {
  border: 2px #fff solid;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    border: 2px $base-colour solid;
    color: $base-colour;
  }
}