@each $breakpoint in $breakpoints {

  $key   : nth($breakpoint, 1);
  $value : nth($breakpoint, 2);

  .visible-#{$key} {
    display: none;
    @media only screen and #{$value} {
      display: inline-block !important;
    }
  }

  .hidden-#{$key} {
    @media only screen and #{$value} {
      display: none !important;
    }
  }

}