/*
 * Media query to respond to a minimum size (mobile first)
 */

@mixin resp-min($size) {
  @media screen and (min-width: $size) { 
    @content; 
  }
}


/*
 * Media query to respond to a maximum size
 */

@mixin resp-max($size) {
  @media screen and (max-width: $size) { 
    @content; 
  }
}


/*
 * Output font size in px/rem
 */

@mixin font-size($px) {
  font-size: $px + px;
  font-size: #{$px / 16}rem;
}


/*
 * Placeholder mixin for <input>
 */

@mixin placeholder() {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}


/**
 * Clearfix
 */

@mixin clearfix() {
  &:before,
  &:after{
    content: " ";
    display: table;
  }
  &:after{
    clear: both;
  }
}
