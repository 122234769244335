.itinerary {
	background-color: #fff;
	padding: $base-spacing-unit;
	margin-bottom: $base-spacing-unit;
	position: relative;
	min-height: 560px;
	img {
		max-width: 100%;
		width: 100%;
	}
	strong {
		font-family: $alt-font-stack;
		font-weight: 300;
		font-style: italic;
		display: block;
		clear: both;
		margin-bottom: 1em;
	}
	.itinerary__image {
		margin-bottom: 1em;
	}
}

.more-details {
	background-color: #000;
	color: #fff;
	text-transform: uppercase;
	padding: 0.25em 1em;
	position: absolute;
	bottom: 0;
	right: 0;
	font-weight: bold;
}

.category-heading {
	color: #fff;
	text-shadow: 1px 1px #000;
	margin-bottom: $base-spacing-unit;
	h1 {
		text-transform: uppercase;
		font-family: $base-font-stack;
	}
}

header.heading {
	.icon {
		font-size: 2.5rem;
	}
	.archive.category & {
		padding: $base-spacing-unit;
	}
}

.polar {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-polar;
	}
}

.desert {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-desert;
	}
}

.jungle {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-jungle;
	}
}

.mountain {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-mountain;
	}
}

.river {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-river;
	}
}

.epic-weekends {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-weekends;
	}
}

.world-firsts {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-firsts;
	}
}

.a-trip-with-no-name {
	main &, &.current-menu-item a, &.menu-item a:hover, &.current-post-ancestor, &.menu-item:hover:before, , &.current-menu-item:before, &.current-post-ancestor:before {
		color: $colour-noname;
	}
}

