footer {
	position: absolute;
	bottom: $base-spacing-unit / 2;
	z-index: 2;
	color: #fff;
	right: $base-spacing-unit;
    @include resp-max($breakpoint-sm) {
      text-align: center;
      width: 100%;
      right: 0;
      li {
      	text-align: center;
      }
    }
}

body.home {
	footer {
		position: absolute;
		text-shadow: 1px 1px #000;
	}
}