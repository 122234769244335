/**
Theme Name: Bare Bones
Theme URI: http://github.com/benchmarkstudios/barebones
Author: Benchmark
*/

// 1. Vendors
@import
    'vendor/simple-grid/simple-grid',
    'vendor/slick';

// 2. Configuration and helpers
@import
    'utilities/variables',
    'utilities/mixins',
    'utilities/reset',
    'utilities/a11y',
    'utilities/responsive',
    'utilities/wordpress';

// 3. Base stuff
@import
    'base/shared',
    'base/images',
    'base/forms',
    'base/lists',
    'base/headings',
    'base/links';

// 4. Layout-related sections
@import
    'layout/main',
    'layout/header',
    'layout/footer';

// 5. Components
@import
    'modules/buttons',
    'modules/nav',
    'modules/slider';

// 6. Page-specific styles
@import
    'pages/home',
    'pages/category',
    'pages/single',
    'pages/page';