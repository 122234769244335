/**
 * Custom variables
 */



/**
 * Base values
 */

$base-colour:            #000;
$base-background-colour: #fff;
$base-font-stack:        'PT Sans', sans-serif;
$base-font-size:         14;
$base-line-height:       1.3;
$base-spacing-unit:      ($base-font-size * $base-line-height) * 1px;
$alt-font-stack:		 'PT Serif', serif;

/**
 * Breakpoints
 */

$breakpoint-sm: 768px;
$breakpoint-md: 1025px;
$breakpoint-lg: 1200px;

$breakpoints: (
  'lg' '(min-width: ' + $breakpoint-lg + ')',
  'md' '(min-width: ' + $breakpoint-md + ') and (max-width: ' + ($breakpoint-lg - 1) + ')',
  'sm' '(min-width: ' + ($breakpoint-sm + 1) + ') and (max-width:'  + ($breakpoint-md - 1) + ')',
  'xs' '(max-width: ' + $breakpoint-sm + ')'
);


/**
 * Grid
 */

$grid-max-width:    1200px;
$grid-columns:      12;
$grid-gutter:       $base-spacing-unit;

$header-width:		225px;

/**
 * Category Colours
 */

$colour-polar: #4AA7D0;
$colour-desert: #EA9A3E;
$colour-jungle: #1E5731;
$colour-mountain: #925E32;
$colour-river: #243357;
$colour-weekends: #4f0e3c;
$colour-firsts: #aa2d2d;
$colour-noname: #47475E;
